import { css } from '@emotion/react';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Box, Button, desktopFirstMediaQuery, Icon, Title } from '@inflearn/ds-react';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SessionPagesDto from '../../../../../dtos/SessionPagesDto';
import useSessionProgramPages from '../../../../../hooks/useSessionProgramPages';
import useSessionSpeakerPages from '../../../../../hooks/useSessionSpeakerPages';
import { URLS } from '../../../../../utils/urls';
import { styleSection } from '../InteractionSections';
import { commonSectionItemVariants } from '../InteractionSections/variants';
import SessionCard, { CARD_WIDTH, MOBILE_CARD_WIDTH } from './SessionCard';
import { sampleSize } from 'lodash-es';
import Background from './Background';

const CARD_GAP = 20;

const SessionFlowList = () => {
  const { data: speakersResponse } = useSessionSpeakerPages();
  const { data: programResponse } = useSessionProgramPages();
  const [isHover, setIsHover] = useState(false);
  const tickerAnimationRef = useRef<Animation | null>(null);

  const sessions = useMemo(() => {
    if (speakersResponse === undefined) {
      return [];
    }

    return new SessionPagesDto(speakersResponse).sessions;
  }, [speakersResponse]);

  const program = useMemo(() => {
    if (programResponse === undefined) {
      return [];
    }

    return new SessionPagesDto(programResponse).sessions;
  }, [programResponse]);

  const { cardList, copySessionCardList } = useMemo(() => {
    const cardList = sampleSize([...sessions, ...program], 5);
    return {
      cardList,
      copySessionCardList: [...cardList, ...cardList]
    };
  }, [sessions, program]);

  const tickerDurationTime = useMemo(() => {
    return cardList.length * 2.5 * 1000;
  }, [cardList]);

  const transitionWidth = useMemo(() => {
    return (CARD_WIDTH + CARD_GAP) * cardList.length;
  }, [cardList]);

  const mobileTransitionWidth = useMemo(() => {
    return (MOBILE_CARD_WIDTH + CARD_GAP) * cardList.length;
  }, [cardList]);

  const setAnimateOnTicker = useCallback(
    (transitionWidth: number) => {
      const ticker = document.getElementById('session-ticker');
      if (ticker != null) {
        const tickerAnimation = ticker.animate(
          [
            {
              transform: 'translateX(0)'
            },
            {
              transform: `translateX(${-transitionWidth}px)`
            }
          ],
          {
            duration: tickerDurationTime,
            easing: 'linear',
            iterations: Infinity
          }
        );

        tickerAnimationRef.current = tickerAnimation;
      }
    },
    [tickerDurationTime]
  );

  const updateAnimateOnTicker = useCallback(() => {
    const durationTime =
      typeof window !== 'undefined' && window.innerWidth < 768
        ? mobileTransitionWidth
        : transitionWidth;
    setAnimateOnTicker(durationTime);
  }, [mobileTransitionWidth, transitionWidth]);

  useEffect(() => {
    updateAnimateOnTicker();
  }, [cardList]);

  useEffect(() => {
    window.addEventListener('resize', updateAnimateOnTicker);

    return () => {
      window.removeEventListener('resize', updateAnimateOnTicker);
    };
  }, []);

  useEffect(() => {
    if (tickerAnimationRef.current !== null) {
      tickerAnimationRef.current.updatePlaybackRate(isHover ? 0.5 : 1);
    }
  }, [isHover]);

  if (speakersResponse === undefined) {
    return <></>;
  }

  return (
    <motion.section
      css={[
        styleSection,
        {
          padding: '200px 0px',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          overflowX: 'hidden',
          [desktopFirstMediaQuery.mobile]: {
            padding: '80px 0'
          }
        }
      ]}>
      <Background />
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        variants={commonSectionItemVariants({ delay: 0.1 })}>
        <Title align="center" order={2} mb={40} css={styleSessionFlowListTitle}>
          인프콘 2023
          <br />
          발표 세션을 소개합니다.
        </Title>
      </motion.div>
      <Box
        css={{
          display: 'flex',
          justifyContent: 'center'
        }}>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
          variants={commonSectionItemVariants({ delay: 0.2 })}>
          <a href={URLS.INFCON_2023__SESSION}>
            <Button
              rightIcon={<Icon icon={faArrowRight} />}
              size="xl"
              radius="xl"
              variant="outline"
              css={{
                backgroundColor: 'transparent',
                ':hover': {
                  backgroundColor: '#25262b',
                  color: 'white'
                }
              }}
              color="dark">
              전체 세션 보러 가기
            </Button>
          </a>
        </motion.div>
      </Box>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        variants={commonSectionItemVariants({ delay: 0.3 })}
        css={{
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'visible',
          padding: '80px 0',
          height: '520px',
          [desktopFirstMediaQuery.mobile]: {
            padding: '40px 0',
            height: '340px'
          }
        }}>
        <div
          id="session-ticker"
          css={{
            position: 'absolute',
            width: 'fit-content'
          }}
          onMouseEnter={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}>
          <Box css={styleSessionFlowList} component="ul">
            {copySessionCardList.map((data, index) => {
              return (
                <Box component="li" key={index}>
                  <SessionCard
                    subType={data.subType}
                    type={data.trackName.split(/\s*,\s*/)[0]}
                    title={data.title}
                    speakerName={data.speakerName}
                    company={data.affiliation}
                    image={data.profileUrl}
                    id={data.id}
                  />
                </Box>
              );
            })}
          </Box>
        </div>
      </motion.div>
    </motion.section>
  );
};

const styleSessionFlowListTitle = css({
  marginBottom: '40px',
  fontSize: '60px',
  [desktopFirstMediaQuery.tablet]: {
    fontSize: '54px'
  },
  [desktopFirstMediaQuery.mobile]: {
    marginBottom: '24px',
    fontSize: '32px'
  }
});
const styleSessionFlowList = css({
  overflow: 'visible',
  display: 'flex',
  gap: `${CARD_GAP}px`
});

export default SessionFlowList;
